// Custom css for project

@import "~@kopjra/uikit/dist/src/css/mainUIStyle";
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Caveat&family=Shadows+Into+Light&family=Stalemate&display=swap');

.caveat {
  font-family: Caveat, sans-serif;
}

.shadowsintolight {
  font-family: "Shadows Into Light", sans-serif;
}

.allura {
  font-family: "Allura", sans-serif;
}

.stalemate {
  font-family: "Stalemate", sans-serif;
}

.text {
  font-family: KTextFont, sans-serif;
  letter-spacing: 0.7px;
  color: $dark;
}

.bigNumberColored {
  font-weight: normal;
  font-size: 45px;
  color: #2BD18F;
}

.bigNumberGray {
  font-weight: normal;
  font-size: 45px;
  color: #4E596B
}

.bigNumberLightGray {
  font-weight: normal;
  font-size: 45px;
  color: #8C9AB1
}

// Template Toolbar
.toolbar {
  background: #ffffff 0 0 no-repeat padding-box;
  border-bottom: #ececec 1px solid;
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  box-shadow: 0 3px 8px -3px #00000029;
  z-index: 10;

  .separator {
    border-right: 1px solid $cmp-bg-hover;
    height: 100%;
    display: inline;
    margin: 0 10px;
  }

  .active {
    &.nohover:hover {
      color: $primary;
    }
    color: $primary;
  }

  .nohover:hover {
    color: $secondary;
  }
}

.leftButtonSet {
  text-align: left;
  height: 100%;
  width: 33.3%;
  padding-left: 10px;
  padding-top: 13px;

  .tbButton {
    padding: 0 10px;
    margin-top: 0;
  }
}
.centerButtonSet {
  text-align: center;
  height: 100%;
  width: 33.3%;
  padding-top: 5px;

  .tbButton {
    padding: 0 10px;
    margin-top: 0;
  }
}
.rightButtonSet {
  text-align: right;
  height: 100%;
  width: 33.3%;
  padding-right: 10px;
  padding-top: 11px;

  .tbButton {
    padding: 0 10px;
    margin-top: 0;
  }
}

.tbButton {
  font-size: 20px;
  padding: 5px 10px;
  cursor: pointer;
  color: $secondary;
  margin-top: 5px;
  display: inline-block;
  background-color: $light;
  border: none;

  div {
    display: flex;
    align-items: center;
  }

  &[disabled] {
    color: $primary;
  }
}

.font-lato {
  font-size: 10px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.tbButton:hover {
  color: $primary;
}

.tbInputLabel {
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  color: #4e596b;
  display: inline-block;
  font-family: Lato, sans-serif;

  input {
    border: $lightish solid 1px;
    border-radius: 0;
  }
}

.tbInput {
  width: 30px;
  height: 25px;
  font-size: 14px;
  color: #363d49;
  text-align: center;
}

.tbInput:focus {
  outline: none;
  box-shadow: none;
}

.external-border {
  border: $lightish solid 1px;

  &.error {
    border: $danger solid 1px;
  }
}

// this fixes the height issue when editing a cell in react data-grid.
.data-grid-container .data-grid .cell > div > input.data-editor {
  height: 100%;
  width: 100%;
  border: 0;
}

.data-grid {
  width: 100%;
}

.data-grid-container .data-grid .cell.read-only {
  background-color: #dfe7f5;
  letter-spacing: 1.65px;
  color: #4e596b;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
}

.data-grid-container .data-grid .cell .value-viewer {
  padding-left: 15px;
  text-align: left;
}

.data-grid-container .data-grid .cell {
  font-size: small;
  text-align: left;
}
.data-grid-container .data-grid .cell.read-only.nowrap {
  background-color: #dfe7f5;
}

tr:last-child td:first-child span.value-viewer {
  color: $lightish;
}

.template-field-editor-icon {
  font-size: 17px;
}

.template-field-editor-title {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: $secondary;
  text-transform: uppercase;
}

.template-field-editor-action {
  cursor: pointer;
  &.danger:hover {
    color: $danger;
  }
}

.field-accordion {
  .accordion-container {
    margin-top: -20px;
  }
}

#snapCanvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}

.offcanvas {
  z-index: 1500;
}

.errors {
  text-align: left;
  span, ul {
    color: $danger;
    font-family: KTextFont, sans-serif;
    font-size: 12px;
    letter-spacing: 0.6px;
  }
  span {
    font-weight: bold;
  }
}